import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CustomRoutes from "./Routes/Routes";

/**
 * The main component of the application.
 *
 * @returns {JSX.Element} The rendered App component.
 */
function App() {
  /**
   * Generates the routes for the application.
   *
   * @param {Array} allRoutes - An array of route objects.
   * @returns {Array} An array of Route components.
   */
  const getRoutes = (allRoutes) => {
    return allRoutes.map((route, index) => {
      //console.log(route.component);

      return (
        <Route exact key={index} path={route.path} element={route.component} />
      );
    });
  };

  return (
    <div className="app">
      <head>
        <title>new one </title>
      </head>
      <Router>
        <Routes>{getRoutes(CustomRoutes)}</Routes>
      </Router>
    </div>
  );
}

export default App;
