import React, { useState } from "react";
import "./newProducts.css";
import FloatingCard from "../../molecules/FloatingCard/FloatingCard";

const NewProducts = ({ data, HandleSeeDetails, setSelectedProduct }) => {
  const productList = data;

  return (
    <div className="NewProducts">
      {productList.map((product, index) => (
        <FloatingCard
          key={index}
          {...product}
          HandleSeeDetails={HandleSeeDetails}
          setSelectedProduct={setSelectedProduct}
        />
      ))}
    </div>
  );
};

export default NewProducts;
