import React, { useState } from "react";
import CustomNavbar from "../../organism/NavBar/Navbar";
import HomeCarouselFade from "../../atoms/Carousel/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import Footer from "../../organism/footer/Footer";
import Content from "../../molecules/Content/Content";

/**
 * Renders the Home page.
 * @returns {JSX.Element} The rendered Home page component.
 */

const Home = () => {
  let navHeds = {
    Feeding: false,
    BathingChanging: false,
    Clothing: false,
    ToysGifts: false,
  };
  return (
    <div className="home2">
      <head>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8759528903104444"
          crossorigin="anonymous"></script>
      </head>
      <CustomNavbar name={navHeds} />
      <HomeCarouselFade />

      <Content />

      {/* <ProductDisplayPage /> */}
      <Footer />
    </div>
  );
};

export default Home;
