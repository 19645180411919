import React from "react";
import "./CustomButton.css";

const CustomButton = ({ text, HandleSeeDetails }) => {
  const handleClick = (e) => {
    e.preventDefault();
    HandleSeeDetails(e);
  };

  return (
    <button className="customBtn" onClick={handleClick}>
      {text}
    </button>
  );
};

export default CustomButton;
