import React, { useState, useEffect } from "react";
import CustomNavbar from "../../organism/NavBar/Navbar";
import "./FloatingCard.css";
import CustomButton from "../../atoms/CustomButton/CustomButton";
import { ReadS3Object } from "../../../utils/awsFetch/awsFetch";
const FloatingCard = ({
  name,
  price,
  imagePath,
  description,
  HandleSeeDetails,
  setSelectedProduct,
}) => {
  //{ name, price, imagePath }
  const selectedPrdSpecs = {
    name: name,
    price: price,
    imagePath: imagePath,
    description: description,
    // category: "Sample Category",
    // brand: "Sample Brand",
    // rating: 4.5,
    // reviews: 100,
    // stock: 10,
  };
  const [imageURL, setImageURL] = useState(null);
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageData = await ReadS3Object(imagePath);
        if (imageData) {
          // Create a Blob object from the image data
          const blob = new Blob([imageData], { type: "image/jpeg" });
          // Create a URL for the Blob object
          const url = URL.createObjectURL(blob);
          setImageURL(url);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage(); // Fetch image when component mounts
  }, [imagePath]); // Empty dependency array ensures it runs only once on mount
  const HandleClick = (e) => {
    setSelectedProduct(selectedPrdSpecs);
    HandleSeeDetails(e);
    //imageFetcher();
  };
  var image = require("../../../Assets/samples/sample1.jpg");
  var image2 = require("../../../Assets/samples/sample2.jpg");
  return (
    <>
      <div class="FloatingCard" onClick={(e) => HandleClick(e)}>
        <img src={imageURL} alt={name} />
        <div className="Card_Body">
          <h5>{name}</h5>
          <p>LKR {price}</p>
        </div>
        <div className="CardButton">
          <CustomButton text={"See Details"} HandleSeeDetails={HandleClick} />
        </div>
      </div>
    </>
  );
};

export default FloatingCard;
