import React, { useState, useEffect } from "react";
import "./PrdDisplayCard.css";
import Loader from "../../atoms/Loader/Loader";
import { ReadS3Object } from "../../../utils/awsFetch/awsFetch";
const PrdDisplayCard = ({ selectedProduct }) => {
  var image1 = require("../../../Assets/samples/sample1.jpg");
  var image2 = require("../../../Assets/samples/sample2.jpg");

  //{ name, price, imagePath, HandleSeeDetails, setSelectedProduct }
  // selectedProduct = "default"
  // const selectedPrdSpecs = {
  //   name: name,
  //   price: price,
  //   imagePath: imagePath,
  //   description: description,
  //   // category: "Sample Category",
  //   // brand: "Sample Brand",
  //   // rating: 4.5,
  //   // reviews: 100,
  //   // stock: 10,
  // };
  const [imageURL, setImageURL] = useState(null);
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageData = await ReadS3Object(selectedProduct.imagePath);
        if (selectedProduct.imagePath && selectedProduct) {
          // Create a Blob object from the image data
          const boblob = new Blob([imageData], { type: "image/jpeg" });
          // Create a URL for the Blob object
          const url1 = URL.createObjectURL(boblob);
          setImageURL(url1);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage(); // Fetch image when component mounts
  }, [selectedProduct]); // Empty dependency array ensures it runs only once on mount

  return (
    <div class="card">
      {selectedProduct == "default" ? (
        <Loader />
      ) : (
        <div className="cardBody">
          <div className="cardImage">
            <img src={imageURL} alt={selectedProduct.name}></img>
          </div>
          <div className="cardDetails">
            <div class="cardDetailsTitle">{selectedProduct.name} </div>
            <div class="cardDetailsCost">LKR: {selectedProduct.price} </div>
            <div class="cardDetailsDescription">
              <p> {selectedProduct.description}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PrdDisplayCard;
