import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";

/**
 * CustomNavbar component represents the navigation bar for the Mother & Baby Care application.
 * It displays a list of routes and allows the user to navigate to different pages.
 *
 * @component
 * @example
 * return (
 *   <CustomNavbar />
 * )
 */
const CustomNavbar = (props) => {
  const navigate = useNavigate();
  const aroutes = [
    { name: "Food", path: "/food" },
    { name: "Bath", path: "/bath" },
    { name: "Cloth", path: "/cloth" },
    { name: "Toys", path: "/toys" },
  ];
  return (
    <div className="customNavbar">
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Mother & Baby Care</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {aroutes.map((route, key) => {
                return (
                  <Nav.Link
                    onClick={() => {
                      navigate(route.path);
                    }}>
                    {route.name}
                  </Nav.Link>
                );
              })}
            </Nav>
            <Nav>
              {/* <Nav.Link eventKey={2} href="#memes">
                Dank memes
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
