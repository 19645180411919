// const AWS = require("aws-sdk");
// const fs = require("fs");
// const dotenv = require("dotenv");
// dotenv.config();

// FOR ES6
import React from "react";
import AWS from "aws-sdk";
// import fs from "fs";
//import dotenv from "dotenv";
//dotenv.config();

class sawsConnection {
  constructor() {
    if (!sawsConnection.instance) {
      AWS.config.update({
        // Configure AWS SDK with your credentials and region
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });
      // Create a new S3 service object
      this.s3 = new AWS.S3();
      sawsConnection.instance = this;
    }
    // Return the existing instance
    return sawsConnection.instance;
  }
  // A method to list buckets
  listBuckets(callback) {
    // Invoke listBuckets on the S3 service object
    this.s3.listBuckets(callback);
  }

  getObject(callback) {
    this.s3.getObject(callback);
  }
  listObjectsV2(callback) {
    this.s3.listObjectsV2(callback);
  }
}

export const getS3BucketList = async () => {
  // Create an S3 service object
  // const s3 = new AWS.S3();
  const obj = new sawsConnection();

  // const data = await s3.getObject(newParams);
  await obj.s3.listBuckets((err, data) => {
    if (err) {
      console.error("Error listing buckets:", err);
    } else {
      console.log(data.Buckets);
    }
  });
};

// export const ReadS3Object = async (fileName) => {
//   console.log("Reading S3 Object");

//   const newParams = {
//     Bucket: "motherandbabycare",
//     Key: fileName,
//   };
//   const obj = new sawsConnection();
//   obj.s3.getObject(newParams, (err, data) => {
//     if (err) {
//       console.error(err);
//     } else {
//       // fs.writeFileSync("example2.jpg", data.Body);
//       console.log("do soemthing with data ");
//       return data.Body;
//     }
//   });
// };

export const ListObjectsInS3 = async (fileName) => {
  console.log("Reading stuff in motherandbabycare bucket");

  const newParams = {
    Bucket: "motherandbabycare",
  };
  const obj = new sawsConnection();
  obj.s3.listObjectsV2(newParams, (err, data) => {
    if (err) {
      console.error(err);
    } else {
      console.log(data.Contents);
    }
  });
};
export const ReadS3Object = async (fileName) => {
  console.log("Reading S3 Object");

  const newParams = {
    Bucket: "motherandbabycare",
    Key: fileName,
  };

  const obj = new sawsConnection();

  try {
    const data = await new Promise((resolve, reject) => {
      obj.s3.getObject(newParams, (err, data) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          // fs.writeFileSync("example2.jpg", data.Body);
          console.log("Got data from S3");
          resolve(data);
        }
      });
    });

    return data.Body;
  } catch (error) {
    console.error("Error reading S3 object:", error);
    return null;
  }
};
