import Carousel from "react-bootstrap/Carousel";
import React from "react";
import "./HomeCarouselFade.css";

/**
 * Renders a fade carousel component with images and captions.
 * @returns {JSX.Element} The rendered Carousel component.
 */
function HomeCarouselFade() {
  var imgg;
  let CarouselImgAry = [];
  //Loop through the images in Asset Folder and add them to the CarouselImgAry
  for (var i = 1; i < 2; i++) {
    imgg = require("../../../Assets/homePageSlide/" + i + ".jpg");
    CarouselImgAry.push(imgg);
  }

  return (
    <Carousel fade>
      {CarouselImgAry.map((imgg) => (
        <Carousel.Item>
          <div className="carousel">
            <img className="carousel_img" src={imgg} alt="slide" />
          </div>
          <Carousel.Caption>
            {/* <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default HomeCarouselFade;
