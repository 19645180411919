import React, { useState } from "react";
import NewProducts from "../../organism/newProducts/newProducts";
import CustomNavbar from "../../organism/NavBar/Navbar";
// import ProductDisplayPage from "../Products/productDisplayPage";
import Popup from "../../organism/Popup/Popup";
import Footer from "../../organism/footer/Footer";
import "./Food.css";
const Food = () => {
  let navHeds = {
    Feeding: true,
    BathingChanging: false,
    Clothing: false,
    ToysGifts: false,
  };

  const sampleFoodProducts = [
    {
      productId: 1,
      name: "Organic Green Tea",
      price: 10,
      description:
        "Premium organic green tea leaves sourced from high-altitude gardens.",
      category: "Food",
      imagePath: "sample1.jpg",
    },
    {
      productId: 3,
      name: "Stainless Steel Water Bottle",
      price: 20,
      description:
        "Eco-friendly stainless steel water bottle with double-wall insulation, keeping your drinks hot or cold for hours.",
      category: "Food",
      imagePath: "sample3.jpg",
    },
    {
      productId: 7,
      name: "Quinoa Superfood Salad Mix",
      price: 12,
      description:
        "Nutrient-rich quinoa salad mix with a variety of organic vegetables and flavorful dressing.",
      category: "Food",
      imagePath: "sample7.jpg",
    },
    {
      productId: 1,
      name: "Organic Green Tea",
      price: 10,
      description:
        "Premium organic green tea leaves sourced from high-altitude gardens.",
      category: "Food",
      imagePath: "sample1.jpg",
    },
    {
      productId: 3,
      name: "Stainless Steel Water Bottle",
      price: 20,
      description:
        "Eco-friendly stainless steel water bottle with double-wall insulation, keeping your drinks hot or cold for hours.",
      category: "Food",
      imagePath: "sample3.jpg",
    },
    {
      productId: 7,
      name: "Quinoa Superfood Salad Mix",
      price: 12,
      description:
        "Nutrient-rich quinoa salad mix with a variety of organic vegetables and flavorful dressing.",
      category: "Food",
      imagePath: "sample7.jpg",
    },
    {
      productId: 1,
      name: "Organic Green Tea",
      price: 10,
      description:
        "Premium organic green tea leaves sourced from high-altitude gardens.",
      category: "Food",
      imagePath: "sample1.jpg",
    },
    {
      productId: 3,
      name: "Stainless Steel Water Bottle",
      price: 20,
      description:
        "Eco-friendly stainless steel water bottle with double-wall insulation, keeping your drinks hot or cold for hours.",
      category: "Food",
      imagePath: "sample3.jpg",
    },
  ];
  const [popup, setPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("default");
  const [products, setProducts] = useState("default");
  const HandleSeeDetails = () => {
    console.log("See Details Clicked");

    setPopup(!popup);
  };

  return (
    <div className="food">
      <CustomNavbar name={navHeds} />
      <div className="food_heading">Food Products</div>
      <div className="food_products ">
        {/* <ProductDisplayPage /> */}
        {popup && (
          <Popup selectedProduct={selectedProduct} setPopup={setPopup} />
        )}
        <NewProducts
          data={sampleFoodProducts}
          HandleSeeDetails={HandleSeeDetails}
          setSelectedProduct={setSelectedProduct}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Food;
