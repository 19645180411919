import Loader from "../Components/atoms/Loader/Loader";

import Home from "../Components/Pages/Home/home";
import Food from "../Components/Pages/Food/Food";
import Bath from "../Components/Pages/Bath/Bath";
import Cloth from "../Components/Pages/Cloth/Cloth";
/**
 Array of custom routes.
 */
const CustomRoutes = [
  { type: "General", path: "/", component: <Home /> },
  { path: "/food", component: <Food /> },
  { path: "/bath", component: <Bath /> },
  { path: "/cloth", component: <Cloth /> },
  { path: "/loader", component: <Loader /> },
];

export default CustomRoutes;
