import React, { useEffect, useState } from "react";
import "./Content.css";
import FloatingCard from "../FloatingCard/FloatingCard";
import NewProducts from "../../organism/newProducts/newProducts";
import CustomRadioButton from "../../atoms/CustomRadioButton/CustomRadioButton";
import Popup from "../../organism/Popup/Popup";
import { ReadS3Object } from "../../../utils/awsFetch/awsFetch";
const Content = () => {
  const SampleProductList = [
    {
      productId: 1,
      name: "Organic Green Tea",
      price: 10,
      description:
        "Premium organic green tea leaves sourced from high-altitude gardens.",
      category: "Food",
      imagePath: "sample1.jpg",
    },
    {
      productId: 2,
      name: "Luxury Bath Towel Set",
      price: 40,
      description:
        "Indulge in ultimate comfort with this luxurious bath towel set made from high-quality, ultra-absorbent materials.",
      category: "Bath",
      imagePath: "sample2.jpg",
    },
    {
      productId: 3,
      name: "Stainless Steel Water Bottle",
      price: 20,
      description:
        "Eco-friendly stainless steel water bottle with double-wall insulation, keeping your drinks hot or cold for hours.",
      category: "Food",
      imagePath: "sample3.jpg",
    },
    {
      productId: 4,
      name: "Organic Cotton T-Shirt",
      price: 25,
      description:
        "Comfortable and sustainable organic cotton t-shirt, perfect for everyday wear.",
      category: "Cloth",
      imagePath: "sample4.jpg",
    },
    {
      productId: 5,
      name: "Aromatherapy Bath Bombs",
      price: 15,
      description:
        "Transform your bath into a luxurious spa experience with these aromatic bath bombs, crafted with natural essential oils.",
      category: "Bath",
      imagePath: "sample5.jpg",
    },
    {
      productId: 6,
      name: "Handwoven Linen Tablecloth",
      price: 30,
      description:
        "Elevate your dining experience with this handwoven linen tablecloth, featuring intricate patterns and superior craftsmanship.",
      category: "Cloth",
      imagePath: "sample6.jpg",
    },
    // Add more products as needed
  ];
  const sampleFoodProducts = [
    {
      productId: 1,
      name: "Organic Green Tea",
      price: 10,
      description:
        "Premium organic green tea leaves sourced from high-altitude gardens.",
      category: "Food",
      imagePath: "sample1.jpg",
    },
    {
      productId: 3,
      name: "Stainless Steel Water Bottle",
      price: 20,
      description:
        "Eco-friendly stainless steel water bottle with double-wall insulation, keeping your drinks hot or cold for hours.",
      category: "Food",
      imagePath: "sample3.jpg",
    },
    {
      productId: 7,
      name: "Quinoa Superfood Salad Mix",
      price: 12,
      description:
        "Nutrient-rich quinoa salad mix with a variety of organic vegetables and flavorful dressing.",
      category: "Food",
      imagePath: "sample7.jpg",
    },
  ];

  const sampleClothProducts = [
    {
      productId: 4,
      name: "Organic Cotton T-Shirt",
      price: 25,
      description:
        "Comfortable and sustainable organic cotton t-shirt, perfect for everyday wear.",
      category: "Cloth",
      imagePath: "sample4.jpg",
    },
    {
      productId: 6,
      name: "Handwoven Linen Tablecloth",
      price: 30,
      description:
        "Elevate your dining experience with this handwoven linen tablecloth, featuring intricate patterns and superior craftsmanship.",
      category: "Cloth",
      imagePath: "sample6.jpg",
    },
    {
      productId: 8,
      name: "Cashmere Scarf",
      price: 50,
      description:
        "Luxuriously soft cashmere scarf to keep you warm and stylish during colder seasons.",
      category: "Cloth",
      imagePath: "sample8.jpg",
    },
  ];

  const sampleBathProducts = [
    {
      productId: 2,
      name: "Luxury Bath Towel Set",
      price: 40,
      description:
        "Indulge in ultimate comfort with this luxurious bath towel set made from high-quality, ultra-absorbent materials.",
      category: "Bath",
      imagePath: "sample2.jpg",
    },
    {
      productId: 5,
      name: "Aromatherapy Bath Bombs",
      price: 15,
      description:
        "Transform your bath into a luxurious spa experience with these aromatic bath bombs, crafted with natural essential oils.",
      category: "Bath",
      imagePath: "sample5.jpg",
    },
    {
      productId: 9,
      name: "Bamboo Bath Caddy",
      price: 35,
      description:
        "Relax and unwind in the bath with this bamboo bath caddy, perfect for holding your favorite book, tablet, or glass of wine.",
      category: "Bath",
      imagePath: "sample9.jpg",
    },
  ];
  //For actual Products
  const [products, setProducts] = useState("default");
  //For Sample Products (mimic actual products for testing purposes)
  const [sampleProducts, setSampleProducts] = useState(sampleFoodProducts);
  const [selectedProduct, setSelectedProduct] = useState("default");
  const [popup, setPopup] = useState(false);
  //setPopup(!popup);

  // useEffect(() => {}, products);
  const HandleRadioButtonClick = (value) => {
    //alert(value);
    if (value === "bath") {
      setSampleProducts(sampleBathProducts);
    } else if (value === "cloth") {
      setSampleProducts(sampleClothProducts);
    } else {
      setSampleProducts(sampleFoodProducts);
    }
  };

  const HandleSeeDetails = () => {
    console.log("See Details Clicked");

    setPopup(!popup);
  };

  return (
    <>
      <div className="content">
        <div className="contentHead">
          <CustomRadioButton HandleRadioButtonClick={HandleRadioButtonClick} />
        </div>
        <div className="contentMid">
          {popup && (
            <Popup selectedProduct={selectedProduct} setPopup={setPopup} />
          )}
          <NewProducts
            data={sampleProducts}
            HandleSeeDetails={HandleSeeDetails}
            setSelectedProduct={setSelectedProduct}
          />
        </div>
      </div>
    </>
  );
};

export default Content;
