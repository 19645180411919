import React from "react";
import "./Footer.css";

import { ZondiconsHeart } from "../../atoms/heart/heart";
const Footer = () => {
  var imgg;
  let shopImgAry = [];
  for (var i = 1; i < 2; i++) {
    imgg = require("../../../Assets/shopImages/" + i + ".jpg");
    shopImgAry.push(imgg);
  }

  return (
    <div className="footer">
      <div className="footer_container">
        <div className="footer_row_address">
          <p className="footer_text "> Call: +94 770242595 </p>
          <p className="footer_text "> WhatsApp: +94 770242595 </p>
          <p className="footer_text "> Email: Example@email.com </p>
          <p className="footer_text">
            Address: No.108/1 Palaly Road,Thirunelvely Jaffna,SriLanka
          </p>
        </div>
        <img
          className="footer_row_image"
          src={imgg}
          style={{ border: 0 }}
          loading="lazy"></img>
        <iframe
          className="footer_row_image"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1966.4679041726893!2d80.02517411931748!3d9.68652007185284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afe541479a68639%3A0x36a8664a4873390b!2sMother%20%26%20Baby%20care!5e0!3m2!1sen!2slk!4v1680452803983!5m2!1sen!2slk"
          loading="lazy"></iframe>
      </div>
      <div className="copyRights">
        &copy; {new Date().getFullYear()}, made with <ZondiconsHeart /> by
        <a
          href="https://tekkzen.com/"
          target="_blank"
          rel="noopener noreferrer">
          Tekkzen
        </a>
      </div>
    </div>
  );
};

export default Footer;
