import React from "react";
import "./Popup.css";
import PrdDisplayCard from "../../molecules/PrdDisplayCard/PrdDisplayCard";
const Popup = ({ selectedProduct, setPopup }) => {
  const handleContentClick = (e) => {
    //e.preventDefault();
    e.stopPropagation(); // Prevent event propagation to parent element
  };

  return (
    <div className="popup" onClick={() => setPopup()}>
      <div className="popup-content" onClick={(e) => handleContentClick(e)}>
        <PrdDisplayCard selectedProduct={selectedProduct} />
      </div>
    </div>
  );
};

export default Popup;
