import React, { useEffect } from "react";
import "./CustomRadioButton.css";
const CustomRadioButton = ({ HandleRadioButtonClick }) => {
  const [selectedCategory, setSelectedCategory] = React.useState("food");

  const HandleClick = (e) => {
    // e.preventDefault();

    setSelectedCategory(e.target.value);
    HandleRadioButtonClick(e.target.value);
    console.log(e.target.value);
  };
  return (
    <div className="CustomRadioButton">
      <div class="radio-inputs">
        <label class="radio">
          <input
            type="radio"
            value={"food"}
            name="radio"
            checked={selectedCategory === "food"}
            onChange={(e) => HandleClick(e)}
          />
          <span className="name">Food</span>
        </label>
        <label class="radio">
          <input
            type="radio"
            name="radio"
            value={"cloth"}
            checked={selectedCategory === "cloth"}
            onChange={(e) => HandleClick(e)}
          />
          <span className="name">Cloth</span>
        </label>

        <label class="radio">
          <input
            type="radio"
            name="radio"
            value={"bath"}
            checked={selectedCategory === "bath"}
            onChange={(e) => HandleClick(e)}
          />
          <span className="name">Bath</span>
        </label>
      </div>
    </div>
  );
};

export default CustomRadioButton;
